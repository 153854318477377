@charset "UTF-8";

$color : #C4A237;
$color2 : #C4A237;
$themecolor : #C4A237;
$themecolor2 :$color2;

$red: red;

/* label color */
  .input-field label {
    color: $themecolor;
  }
  /* label focus color */
  .input-field input[type=text]:focus + label,
  .input-field textarea:focus + label {
    
  }
  /* label underline focus color */
  .input-field input[type=text]:focus,
  .input-field textarea:focus,
  .input-field input[type=text].valid,
  .input-field textarea.valid,
  input-field input[type=text].invalid,
  .input-field textarea.invalid {
    box-shadow: 0 1px 0 0 $color;



	box-sizing: border-box;
  }
  
  /* icon prefix focus color */
  .input-field .prefix.active {
    color: $color;
  }
  
  input:not([type]), 
  input[type=date], 
  input[type=datetime-local], 
  input[type=datetime], 
  input[type=email], 
  input[type=number], 
  input[type=password], 
  input[type=search], 
  input[type=tel], 
  input[type=text], 
  input[type=time], 
  input[type=url], 
  textarea.materialize-textarea {


  	box-sizing: border-box;
  	font-family: 'PT Serif',serif;
  	-webkit-font-smoothing: antialiased;
  }
  
  input[type=text]:focus, 
  input[type=password]:focus, 
  input[type=email]:focus, 
  input[type=url]:focus, 
  input[type=time]:focus, 
  input[type=date]:focus, 
  input[type=datetime-local]:focus, 
  input[type=tel]:focus, 
  input[type=number]:focus, 
  input[type=search]:focus, 
  textarea.materialize-textarea:focus,
  .materialize-tags:focus,
  .materialize-tags.active{

  	box-shadow: 0 1px 0 0 $color!important;
  }
  
  
  .btn:hover,
  .btn:focus,
  .btn.active,
  .btn:visited,
  .btn-large:hover,
  .btn-large:focus,
  .btn-large.active,
  .btn-large:visited{
  	color: $color;
  	background-color: #333;
  }
  .dropdown-content li > a, .dropdown-content li > span {
      font-size: 1.2rem;
      color: $themecolor;
      display: block;
      padding: 1rem 1rem;
  }
  
  .innerCenterH {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
  }
/* label color */
 .input-field label {
   color: $color;
 }
 /* label focus color */
 .input-field input[type=text]:focus + label {
   color: $color;
 }
 /* label underline focus color */
 .input-field input[type=text]:focus {
   border-bottom: 1px solid $color2;
   box-shadow: 0 1px 0 0 $color2;
 }
 /* valid color */
 .input-field input[type=text].valid {
   border-bottom: 1px solid $color2;
   box-shadow: 0 1px 0 0 $color2;
 }
 /* invalid color */
 .input-field input[type=text].invalid {
   border-bottom: 1px solid $red;
   box-shadow: 0 1px 0 0 $red;
 }
 /* icon prefix focus color */
 .input-field .prefix.active {
   color:$color2;
 }
     
 




.select-wrapper input.select-dropdown {
	    border: 1px solid #C4A237;
		color: $color;
		padding: 20px;
		box-sizing: border-box;
}
.select-dropdown li.disabled, 
.select-dropdown li.disabled>span, 
.select-dropdown li.optgroup {
	color: #C4A237;
}

.caret,
.select-wrapper span.caret {
	font-family: 'Linearicons';
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
		color: #FFF;
}
.caret:after,
.select-wrapper span.caret:after {
	    content: "\e942";
		color: $color;
		    font-size: 20px;
		    padding-right: 8px;
		margin-top: -15px;
		display: block;
	
}

[type=radio]:not(:checked)+label:before,
[type=radio]:checked+label:before {

    border: 1px solid #C4A237;
    color: #C4A237;
    border-radius: 0;
    width: 100%;
    height: 74px;
	    margin: 0;
		display: block;
		padding: 0;
}


[type=radio]:checked+label, 
[type=radio]:not(:checked)+label {
    padding-left: 0px;
    height: 74px;
    line-height: 74px;
}
[type=radio]:not(:checked)+label {
    padding-left: 0;
    height: 74px;
    line-height: 74px;
    width: 100%;
}

[type=radio]:not(:checked)+label {
	background-color: #C4A237;
	color: #FFF;
}
[type=radio]:checked+label {
	background-color: #fff;
	color: #C4A237;
}
[type=radio]:checked+label:before,
[type=radio]:not(:checked)+label:before {
    border: 1px solid #C4A237;
	
}
[type=radio]:not(:checked)+label,
[type=radio]+label {
	text-align: center;
	display: block;
	width: 100%;
	font-family: 'PT Serif',serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-ms-font-smoothing: antialiased;
}
[type=radio]:checked+label::after {
	content: none!important;
}

#newshome .newshead2 a i,
.bgor a i,
.newsitem a i,
.btmright i,
.list1 li a i,
.servicesbloc li a i,
.articleitem a i {
	@include transition(padding-left .3s ease);
}
#newshome .newshead2 a:hover i,
.bgor a:hover i,
.newsitem a:hover i,
.btmright:hover i,
.list1 li a:hover i,
.servicesbloc li a:hover i,
.articleitem a:hover i {
	padding-left: 10px;
}


input:not([type]), input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.materialize-textarea,
.select-wrapper input.select-dropdown {
	height: auto;
	    line-height: 2rem;
}

input:not([type]):focus, 
input[type=date]:focus, 
input[type=datetime-local]:focus, 
input[type=datetime]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
input[type=password]:focus, 
input[type=search]:focus, 
input[type=tel]:focus, 
input[type=text]:focus, 
input[type=time]:focus, 
input[type=url]:focus, 
input[type=text]:focus, 
textarea.materialize-textarea:focus,
textarea:focus {
	border-color: #41484A!important;
	box-shadow: 0 1px 0 0 #41484A!important;
	color: #41484A;
}

input[type=text]:focus::-webkit-input-placeholder,
input[type=tel]:focus::-webkit-input-placeholder,
input[type=email]:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #41484A;
}
input[type=text]:focus::-moz-placeholder,
input[type=tel]:focus::-moz-placeholder,
input[type=email]:focus::-moz-placeholder,
textarea:focus::-moz-placeholder { /* Firefox 19+ */
  color: #41484A;
}
input[type=text]:focus:-ms-input-placeholder,
input[type=tel]:focus:-ms-input-placeholder,
input[type=email]:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder { /* IE 10+ */
  color: #41484A;
}