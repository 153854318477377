@charset "UTF-8";


$black: #333;
$color_theme: #dc1818;

/*
normalise
*/
@import "vendors/animate";
@import "componement/base";
@import "componement/mixins";

@import "componement/over_materialize";
//@import "componement/menu";
@import "vendors/grid";
//@import "vendors/slick-theme";
//@import "vendors/slick";
@import "componement/icons";
@import "vendors/jqueryfancybox";

@import url('https://fonts.googleapis.com/css?family=Merriweather|Muli');

$typo1 : 'Merriweather', serif;
$typo2 : 'Roboto',sans-serif;
/*
font-family: 'Roboto', sans-serif;
font-family: 'bw_gradualregular';
*/
/*
###########################
GO
########################### 
*/

.luminosity:before{    
	mix-blend-mode: hard-light;
}
      
body,html{
	    min-height: 100vh;
		color: #020633;
		font-family: $typo2;
		font-weight: 400 !important;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		-o-font-smoothing: antialiased;
		letter-spacing: 0px;
}
body main{
	
	@include transition(all .3s ease);
	
}
html,body {

		
		
	font-size: 15px;
	line-height: 30px;
}

b,strong,small {
	/*font-family:$typo1;*/
}


figure{
	outline: 0;
	line-height: 0;
	margin: 0;
	line-height: 0;
}	
section {

	width: 100%;
	overflow: hidden;
}
.hero {
	height: 100vh;
}

.hero .valign-wrapper,
.hero .container {
	height: 100%;
}
.hero2 {
	height: 450px;

}

.bgblu:before {
	background-color: #d1f3f9;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	display: block;
}
h1,h2,h3,h4,h5,h6,#tooltip-bottom {
	font-family: $typo2;
	    /*font-weight: 400 !important;*/
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-font-smoothing: antialiased;
	    -ms-font-smoothing: antialiased;
	    -o-font-smoothing: antialiased;
	    letter-spacing: 0px;
		    margin-top: 5px;
			    font-weight: 300;
			
		
}
.right li {
	display: inline-block;
	margin-right: 10px;
}
.news {
	margin-bottom: 20px;
	display: block;
	position: relative;
	color: #000;
	margin-top: 20px;
}
img {
	max-width: 100%;
}
.logo {
	display: inline-block;
	padding-top: 40px;
	    padding-bottom: 40px;
	    font-family: Roboto,sans-serif;
}
.right {
	padding-top: 25px;
}
.right li a {
	color: #666;
}

section {
	background-color: #e5f6f9;
}
section.nav {
	background-color: #FFF;
}
.padd {
	padding-bottom: 40px;
	padding-top: 40px;
}
.txt {
	font-weight: 300;
	color: #777777;
	line-height: 32px;
	font-size: 24px;
}
h3 {
	font-weight: 300;
	color: #000;
	line-height: 32px;
	font-size: 24px;
}
h2 {
	font-weight: 600;
	color: #000;
	line-height: 32px;
	font-size: 14px;
}
.row.masonry {
	
	margin-left: -30px;
	padding-left: 0px;
}
.row.masonry .col.item {
	padding: 0;
	padding-left: 30px;
	margin-bottom: 30px;
	margin-top: 0;
}
.row.masonry .col.item .inner {
	background-color: #FFF;
	padding: 30px;
}

.logo {
	font-size: 1.8em;
	font-weight: bold;
}
.right li a {
    color: #333;
    font-size: 1em;
    font-weight: 500;
}
.toright {
	text-align: right;
	color: #333;
	padding-top: 15px;
}
footer a {
	color: #666;
}
.up_next {
    font-size: 40px;
    right: 5%;
	bottom: 5%;
    position: absolute;
}
#gototop {
    position: fixed;
    font-size: 40px;
    right: 5%;
    z-index: 0;
}
.logo span.sub:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 15px;
    height: 2px;
    background-color: hsla(0,0%,100%,.6);
}
.logo span.sub:before {
    background-color: rgba(0,0,0,.6);
}
.logo span.sub {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.25em;
	
	vertical-align: middle;
	font-size: 0.6em;
	position: relative;
	padding-left: 35px;
	opacity: .35;
	line-height: 1.25rem;
}
.logo span.sub {
    position: relative;
    padding-left: 35px;
    opacity: .35;
}
footer .liensfooter a {
	font-size: 30px;
}
footer {
	padding-top: 80px;
	padding-bottom: 80px;
}

.nomargpadd {
	padding: 0;
	margin: 0;
}
footer p {
	margin: 0;
}
footer .logo {
	margin: 0;
	padding: 0;
}
.item .txt {
	font-size: 16px;
	line-height: 22px;
}
.item h3 {
	line-height: 32px;
	margin-top: 16px;
	font-weight: 600;
}



.content--highlight {
    padding: 0;

}
.content {
    position: relative;
    height: 450px;
    padding: 15vh 0 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.flexy--center {
    justify-content: center;
}
.flexy {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#wavebg canvas {
	height: 450px;
	width: 100%;
	
}
#wavebg {
	position: absolute;
	top: 0;
	left: 0;
	height: 450px;
	width: 100%;
	
}

.liste {
	padding-top: 80px;
	padding-bottom: 80px;
}

.sidenav-trigger {
	color: #000;
	position: relative;
	font-size: 30Px;
	display: block;
	margin-top: 34px;
	color: #000;
}

.container_small {
	max-width: 850px;
	margin: 0 auto;
	display: block;
	margin-top: 80px;
	margin-bottom: 140px;
}
.container_small figure,.container_small img {
	min-width: 100%;
}
.txt {
	    line-height: 34px;
		font-size: 18px;
}
.container_small .txt {
	margin-top: 40px;
	text-align: justify;
	color: #000;
}
.container_small h2 {
    font-weight: 600;
    font-size: 20px;
}
.logo a {
	color: #000;
}
.filtering li {
	display: inline-block;
	margin-right: 10px;
	cursor: pointer;
	font-size: 15px;
	text-transform: uppercase;
}
u {
	position: relative;
	text-decoration: none !important;
}
.filtering li.active {
	color: #34E0C2;
}
u:before {
	content: " ";
	position: absolute;
	bottom: -1px;
	left: 0;
	background-color: rgba(#34E0C2,0.5);
	height: 7px;
	width: 100%;
	display: block;
}
#info {
display: none;
}
.navvvv li a {
	position: relative;
}
.navvvv li.active a:before {
	content: " ";
	position: absolute;
	bottom: -1px;
	left: 0;
	background-color: rgba(#34E0C2,0.5);
	height: 7px;
	width: 100%;
	display: block;
}
.imgheight2 {
	height: 300px;
}
.filtering {
	margin-bottom: 40px;
}
	
	
	
.intrinsic-container {
  position: relative;
  height: 0;
  overflow: hidden;
}
 
/* 16x9 Aspect Ratio */
.intrinsic-container-16x9 {
  padding-bottom: 56.25%;
}
 
/* 4x3 Aspect Ratio */
.intrinsic-container-4x3 {
  padding-bottom: 75%;
}
 
.intrinsic-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
h2 {
    font-weight: 600;
    font-size: 18px;
}

	
//@import "componement/galery";
//@import "componement/responsive";
@media screen and(max-width: 992px) {
	.logo {
			position: absolute;
			right: 0;
			top: 0;
			margin: 0;
			padding: 0;
	}
}
@media screen and(max-width: 1024px) {
	.row .col.s4 {
	    width: 50%;
	}
	
}

@media screen and(max-width: 450px) {

	.news .col{
		width: 100%!important;
		float: none;
		padding: 0;
		margin: 0;
		display: block;
	}
	.row .col.s4 {
	    width: 100%;
	}
}

